import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BsXLg } from 'react-icons/bs';

import { Link } from 'react-router-dom';

interface IProps {
  isOpen: boolean;
  toggleHandler(): void;
  onLogin(): void;
}

const navVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const NavSm: React.FC<IProps> = ({ toggleHandler, isOpen, onLogin }) => {
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          variants={navVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="nav-sm"
        >
          <div className="nav-sm__close" onClick={toggleHandler}>
            <BsXLg size={30} />
          </div>

          <a
            onClick={toggleHandler}
            className="nav-sm__item btn btn-outline-primary"
            href="#why-leadremit"
          >
            Why Leadremit?
          </a>

          <a
            onClick={toggleHandler}
            className="nav-sm__item btn btn-outline-primary"
            href="#leadvoucher"
          >
            LeadVoucher&#174;
          </a>

          <a
            onClick={toggleHandler}
            className="nav-sm__item btn btn-outline-primary"
            href="#leadrmall"
          >
            LeadrMall&#174;
          </a>

          <Link
            onClick={toggleHandler}
            className="nav-sm__item btn btn-outline-primary"
            to="#!"
          >
            Developers
          </Link>

          <button
            onClick={() => {
              onLogin();
              toggleHandler();
            }}
            className="nav-sm__item btn btn-outline-primary"
            style={{ backgroundColor: 'transparent' }}
          >
            Login
          </button>

          <Link
            onClick={toggleHandler}
            className="nav-sm__item btn btn-primary header-btn"
            to="/get-started"
          >
            Get Started
          </Link>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NavSm;
