import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

function useIndustries() {
  const [data, setData] = useState<{ Industry: string }[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const sendEmail = async () => {
      setIsLoading(true);
      try {
        const res: AxiosResponse<{ Industry: string }[]> = await axios.get(
          '/industries.json'
        );
        setData(res.data);
        setIsSuccess(true);
        setIsError(false);
      } catch (error) {
        setIsError(true);
        setIsSuccess(false);
        setData(undefined);
      } finally {
        setIsLoading(false);
      }
    };
    sendEmail();
  }, []);

  return {
    isError,
    isLoading,
    isSuccess,
    data,
  };
}

export default useIndustries;
