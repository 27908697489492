import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TGetStartedForm, getStartedSchema } from './get-started-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useCountries from 'hooks/useCountries';
import useSendEmail from 'hooks/useSendEmail';
import { useEffect, useMemo } from 'react';
import emailTemplate from 'utils/email-template';
import Select from 'react-select';
import useIndustries from 'hooks/useIndustries';

const whitelistedCountries = new Set(['nigeria', 'united states']);

function GetStartedPage() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<TGetStartedForm>({
    resolver: yupResolver(getStartedSchema),
    mode: 'onSubmit',
  });

  const { data: countriesRes } = useCountries();
  const { data: industries } = useIndustries();
  const { sendEmail, isLoading, isSuccess } = useSendEmail();

  const countries = useMemo(() => {
    if (!countriesRes) return [];
    return countriesRes.filter((c) =>
      whitelistedCountries.has(c.toLowerCase())
    );
  }, [countriesRes]);

  const submitHandler: SubmitHandler<TGetStartedForm> = (values) => {
    if (isLoading) return;
    if (values.businessCountry.value.toLowerCase() === 'nigeria') {
      window.open('https://merchants.leadremitpayments.com/auth/login');
      return;
    }

    const template = emailTemplate({
      ...values,
      businessCountry: values.businessCountry.value,
      industry: values.industry.value,
      socialMediaUrl: values.socialMediaUrl ?? '',
      website: values.website ?? '',
      message: values.message ?? '',
    });
    sendEmail({
      emailTemplate: '',
      message: template,
      subject: 'New Contact Us Request',
      templateEmail: false,
      templateVariables: {},
      toEmail: 'help@leadremit.com',
    });
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      reset({
        businessCountry: { label: '', value: '' },
        businessEmail: '',
        businessName: '',
        businessPhone: '',
        businessType: '',
        fullName: '',
        industry: { label: '', value: '' },
        message: '',
        socialMediaUrl: '',
        website: '',
      });
    }
  }, [isLoading, isSuccess, reset]);

  return (
    <main className="get-started">
      <div className="get-started__content">
        <div style={{ paddingBottom: '100px' }}>
          <p
            className="xl-text space-grotesk text-primary mb-1"
            style={{ maxWidth: '600px', width: '100%' }}
          >
            Contact our Sales Team
          </p>

          <p
            className="intro-text mb-1"
            style={{ maxWidth: '600px', width: '100%' }}
          >
            Leadremit creates all-round solutions that empower businesses of any
            size to rise to the top in their segment and take the lead.
          </p>

          <p
            className="intro-text mb-1"
            style={{ maxWidth: '600px', width: '100%' }}
          >
            Please complete the form to connect with a member of our team.
          </p>
        </div>

        <form
          onSubmit={handleSubmit(submitHandler)}
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <p className="xl-text space-grotesk text-primary mb-1">Contact Us</p>
          <div className="form-content">
            <div className="form-control">
              <label className={!!errors.fullName ? 'invalid' : ''}>
                Full Name<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className={`control ${!!errors.fullName ? 'invalid' : ''}`}
                {...register('fullName')}
              />
              <span className="error">{errors.fullName?.message}</span>
            </div>

            <div className="form-control">
              <label className={!!errors.businessName ? 'invalid' : ''}>
                Business Name<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className={`control ${!!errors.businessName ? 'invalid' : ''}`}
                {...register('businessName')}
              />
              <span className="error">{errors.businessName?.message}</span>
            </div>

            <div className="form-control">
              <label className={!!errors.businessEmail ? 'invalid' : ''}>
                Business Email<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className={`control ${!!errors.businessEmail ? 'invalid' : ''}`}
                {...register('businessEmail')}
              />
              <span className="error">{errors.businessEmail?.message}</span>
            </div>

            <div className="form-control">
              <label className={!!errors.businessPhone ? 'invalid' : ''}>
                Business Phone<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className={`control ${!!errors.businessPhone ? 'invalid' : ''}`}
                {...register('businessPhone')}
              />
              <span className="error">{errors.businessPhone?.message}</span>
            </div>

            <Controller
              control={control}
              name="businessCountry"
              render={({ field, fieldState }) => (
                <div className="form-control">
                  <label className={!!fieldState.error ? 'invalid' : ''}>
                    Business Country<span style={{ color: 'red' }}>*</span>
                  </label>
                  <Select
                    placeholder="Select Country"
                    className={`control ${!!fieldState.error ? 'invalid' : ''}`}
                    value={field.value}
                    onChange={(v) => field.onChange(v)}
                    options={countries?.map((c) => ({ label: c, value: c }))}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: 'none',
                        boxShadow: 'none',
                      }),
                    }}
                  />
                  <span className="error">{fieldState.error?.message}</span>
                </div>
              )}
            />

            <div className="form-control">
              <label className={!!errors.businessType ? 'invalid' : ''}>
                Business Type<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className={`control ${!!errors.businessType ? 'invalid' : ''}`}
                {...register('businessType')}
              />
              <span className="error">{errors.businessType?.message}</span>
            </div>

            <div className="form-control">
              <label className={!!errors.website ? 'invalid' : ''}>
                Website
              </label>
              <input
                className={`control ${!!errors.website ? 'invalid' : ''}`}
                {...register('website')}
              />
              <span className="error">{errors.website?.message}</span>
            </div>

            <div className="form-control">
              <label className={!!errors.socialMediaUrl ? 'invalid' : ''}>
                Social Media
              </label>
              <input
                className={`control ${
                  !!errors.socialMediaUrl ? 'invalid' : ''
                }`}
                {...register('socialMediaUrl')}
              />
              <span className="error">{errors.socialMediaUrl?.message}</span>
            </div>

            <Controller
              control={control}
              name="industry"
              render={({ field, fieldState }) => (
                <div className="form-control">
                  <label className={!!fieldState.error ? 'invalid' : ''}>
                    Industry<span style={{ color: 'red' }}>*</span>
                  </label>
                  <Select
                    className={`control ${!!fieldState.error ? 'invalid' : ''}`}
                    value={field.value}
                    placeholder="Select Industry"
                    onChange={(v) => field.onChange(v)}
                    options={industries?.map((c) => ({
                      label: c.Industry,
                      value: c.Industry,
                    }))}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: 'none',
                        boxShadow: 'none',
                      }),
                    }}
                  />
                  <span className="error">{fieldState.error?.message}</span>
                </div>
              )}
            />

            <div className="form-control">
              <label className={!!errors.message ? 'invalid' : ''}>
                Enquiry Description<span style={{ color: 'red' }}>*</span>
              </label>
              <textarea
                className={`control ${!!errors.message ? 'invalid' : ''}`}
                rows={5}
                {...register('message')}
              />
              <span className="error">{errors.message?.message}</span>
            </div>

            <button
              className="btn btn-primary header-btn"
              style={{ marginTop: '50px' }}
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

export default GetStartedPage;
