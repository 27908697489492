/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { motion, useViewportScroll } from 'framer-motion';
import { Link } from 'react-router-dom';
import { BsList } from 'react-icons/bs';

import Logo from 'assets/images/logo-color-full.svg';
import NavSm from 'components/nav-sm';
import { Modal, ModalContent } from 'components/Modal';

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -25 },
};

const Nav: React.FC = () => {
  const { scrollY } = useViewportScroll();
  const [hidden, setHidden] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const toggleSideNav = useCallback(() => setShowSideNav((prev) => !prev), []);

  const [modalOpen, setModalOpen] = useState(false);

  const update = () => {
    if (scrollY?.get() < scrollY?.getPrevious()!) {
      setHidden(false);
    } else if (
      scrollY?.get() > 100 &&
      scrollY?.get() > scrollY?.getPrevious()!
    ) {
      setHidden(true);
    }
  };

  useEffect(() => {
    return scrollY.onChange(() => update());
  }, []);

  return (
    <>
      <motion.header
        className="nav"
        // variants={variants}
        // animate={hidden ? 'hidden' : 'visible'}
        // transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
      >
        <nav className="nav__content">
          <div className="nav__content-top">
            <Link to="/" style={{ marginRight: '30px' }}>
              <img src={Logo} className="nav-logo" alt="" />
            </Link>

            <a className="btn btn-nav lg-only" href="/#why-leadremit">
              Why Leadremit?
            </a>

            <a className="btn btn-nav lg-only" href="/#leadvoucher">
              LeadVoucher&#174;
            </a>

            <a className="btn btn-nav lg-only" href="/#leadrmall">
              LeadrMall&#174;
            </a>
          </div>

          <div className="nav__content-bottom">
            <Link className="btn btn-nav lg-only" to="/#!">
              Developers
            </Link>

            <a
              className="btn btn-nav lg-only"
              href="javascript:void(0)"
              onClick={() => setModalOpen(true)}
            >
              Login
            </a>

            <Link
              className="btn btn-primary header-btn lg-only"
              to="/get-started"
            >
              Get Started
            </Link>

            <span className="sm-only nav-sm-toggle" onClick={toggleSideNav}>
              <BsList size={30} />
            </span>
          </div>
        </nav>
      </motion.header>
      <NavSm
        isOpen={showSideNav}
        toggleHandler={toggleSideNav}
        onLogin={() => setModalOpen(true)}
      />

      <Modal isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
        <ModalContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '24px',
              flex: 1,
              width: '100%',
            }}
          >
            <h1 className="space-grotesk" style={{ fontSize: '24px' }}>
              Select your Country
            </h1>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <a
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  borderRadius: '4px',
                  fontSize: '18px',
                  fontWeight: '500!important',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                href="https://merchants.leadremitpayments.com/auth/login"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 6 3"
                  width="32"
                  height="32"
                >
                  <rect width="6" height="3" fill="#008751" />
                  <rect x="2" width="2" height="3" fill="#FFF" />
                </svg>
                <span>Nigeria</span>
              </a>
              <a
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  borderRadius: '4px',
                  fontSize: '18px',
                  fontWeight: '500!important',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                href="https://www.leadremitdashboard.com"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 7410 3900"
                  width="32"
                  height="32"
                >
                  <path fill="#b22234" d="M0 0h7410v3900H0z" />
                  <path
                    d="M0 450h7410m0 600H0m0 600h7410m0 600H0m0 600h7410m0 600H0"
                    stroke="#fff"
                    stroke-width="300"
                  />
                  <path fill="#3c3b6e" d="M0 0h2964v2100H0z" />
                  <g fill="#fff">
                    <g id="d">
                      <g id="c">
                        <g id="e">
                          <g id="b">
                            <path
                              id="a"
                              d="M247 90l70.534 217.082-184.66-134.164h228.253L176.466 307.082z"
                            />
                            <use xlinkHref="#a" y="420" />
                            <use xlinkHref="#a" y="840" />
                            <use xlinkHref="#a" y="1260" />
                          </g>
                          <use xlinkHref="#a" y="1680" />
                        </g>
                        <use xlinkHref="#b" x="247" y="210" />
                      </g>
                      <use xlinkHref="#c" x="494" />
                    </g>
                    <use xlinkHref="#d" x="988" />
                    <use xlinkHref="#c" x="1976" />
                    <use xlinkHref="#e" x="2470" />
                  </g>
                </svg>
                United States
              </a>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Nav;
