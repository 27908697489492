import axios from 'axios';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { EmailDTO } from 'types/email';
import { apiBaseUrl } from 'utils/env';

function useSendEmail() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const sendEmail = useCallback(async (data: EmailDTO) => {
    setIsLoading(true);
    try {
      await axios.post(`${apiBaseUrl}/email`, data);
      toast("Your request has been received. We'll be in touch", {
        type: 'success',
      });
      setIsSuccess(true);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
      toast('An unexpected error has occured', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    sendEmail,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useSendEmail;
