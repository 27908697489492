import React from 'react';
import Logo from 'assets/images/logo-white-full.svg';
import MsgImg from 'assets/images/msg.svg';
import PaperPlaneImg from 'assets/images/paper-plane.svg';
import TwitterIcon from 'assets/images/twitter.svg';
import InstaIcon from 'assets/images/insta.svg';
import FBIcon from 'assets/images/fb.svg';
import INIcon from 'assets/images/In.svg';
import YTIcon from 'assets/images/yt.svg';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <Link to="/">
        <img src={Logo} alt="Leadremit" className="footer__logo" />
      </Link>

      <div className="footer__content">
        <div className="address">
          <a
            href="mailto:help@leadremit.com"
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
            className="footer__link"
          >
            <img
              src={MsgImg}
              alt="logo"
              style={{ marginRight: '5px', width: '25px', height: '25px' }}
            />
            help@leadremit.com
          </a>
          <a href="" className="footer__link">
            <img
              src={PaperPlaneImg}
              alt="logo"
              style={{ marginRight: '5px', width: '25px', height: '25px' }}
            />
            Lagos NG, WA.
          </a>

          <div className="socials">
            <a
              href="https://twitter.com/leadremit?t=n9VbF9vts16fM71PeILmDg&s=08"
              className="socials__link"
              target="_blank"
              referrerPolicy="no-referrer"
              rel="noreferrer"
            >
              <img src={TwitterIcon} alt="twitter" />
            </a>
            <a
              href="https://instagram.com/leadremit?igshid=YmMyMTA2M2Y="
              className="socials__link"
              target="_blank"
              referrerPolicy="no-referrer"
              rel="noreferrer"
            >
              <img src={InstaIcon} alt="instagram" />
            </a>
            <a
              href="https://www.facebook.com/pages/Leadremit%20Inc/107214418513968/"
              className="socials__link"
              target="_blank"
              referrerPolicy="no-referrer"
              rel="noreferrer"
            >
              <img src={FBIcon} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/lead-remit-inc/mycompany/"
              className="socials__link"
              target="_blank"
              referrerPolicy="no-referrer"
              rel="noreferrer"
            >
              <img src={INIcon} alt="linkedin" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCmuVA4_6kLsGzMhVqOU1k1A"
              className="socials__link"
              target="_blank"
              referrerPolicy="no-referrer"
              rel="noreferrer"
            >
              <img src={YTIcon} alt="youtube" />
            </a>
          </div>

          <span id="siteseal">
            <script
              async={true}
              type="text/javascript"
              src="https://seal.godaddy.com/getSeal?sealID=SLYdEalZ97TnGlF26u8YjGv9kq3Wf2XsEbVoCwkV1MXYdvZ1y6rKYJilC0OH"
            ></script>
            {/* <img
							style={{ cursor: 'pointer' }}
							src="https://seal.godaddy.com/images/3/en/siteseal_gd_3_h_l_m.gif"
							onClick={() => {}}
							alt="SSL site seal - click to verify"
						/> */}
          </span>
        </div>

        <div className="company">
          <p className="footer__title">Company</p>
          <a href="" className="footer__link">
            About Us
          </a>
          <a href="" className="footer__link">
            Compliance
          </a>
          <a href="" className="footer__link">
            Customer Relation
          </a>
          <a href="" className="footer__link">
            Careers
          </a>
          <a href="" className="footer__link">
            Terms & Conditions
          </a>
        </div>

        <div className="products">
          <p className="footer__title">Products</p>
          <a href="" className="footer__link">
            LeadVoucher
          </a>
          <a
            href="https://www.leadrmall.com"
            target="_blank"
            className="footer__link"
            rel="noreferrer"
          >
            LeadrMall
          </a>
          <a href="" className="footer__link">
            Pay4You
          </a>
          <a href="" className="footer__link">
            PayLink
          </a>
          <a href="" className="footer__link">
            Virtual Terminal
          </a>
          <a href="" className="footer__link">
            QR Code
          </a>
          <a href="" className="footer__link">
            Bills Payment
          </a>
        </div>

        <div className="support">
          <p className="footer__title">Support</p>
          <a href="" className="footer__link">
            FAQ
          </a>
          <Link to="/get-started" className="footer__link">
            Contact Us
          </Link>
          <a href="" className="footer__link">
            Pricing
          </a>
          {/* <a href="" className="footer__link">
            Sign In
          </a>
          <a href="" className="footer__link">
            Create Account
          </a> */}
          <Link to="/get-started" className="footer__link">
            Contact Sales
          </Link>
        </div>

        <div className="copyright">
          <div className="footer__link">&copy;leadremitpayments</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
