import * as yup from 'yup';

export type TGetStartedForm = {
  fullName: string;
  businessName: string;
  businessEmail: string;
  businessPhone: string;
  businessCountry: Record<'label' | 'value', string>;
  businessType: string;
  website: string | undefined;
  socialMediaUrl: string | undefined;
  industry: Record<'label' | 'value', string>;
  message: string | undefined;
};

export const getStartedSchema = yup.object().shape({
  fullName: yup
    .string()
    .typeError('Enter a valid value')
    .min(4)
    .required()
    .label('Full name'),
  businessName: yup
    .string()
    .typeError('Enter a valid value')
    .min(4)
    .required()
    .label('Business name'),
  businessEmail: yup
    .string()
    .typeError('Enter a valid value')
    .email()
    .required()
    .label('Business email'),
  businessPhone: yup
    .string()
    .typeError('Enter a valid value')
    .matches(
      /^([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})$/,
      'Must be a valid phone number'
    )
    .required()
    .label('Business phone number'),
  businessCountry: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required()
    .label('Business country'),
  businessType: yup
    .string()
    .typeError('Enter a valid value')
    .required()
    .label('Business type'),
  website: yup
    .string()
    .typeError('Enter a valid value')
    // .matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   'Enter correct url!'
    // )
    .label('Website Url'),
  socialMediaUrl: yup
    .string()
    .typeError('Enter a valid value')
    // .matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   'Enter correct url!'
    // )
    .label('Social media url'),
  industry: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required(),
  message: yup
    .string()
    .typeError('Enter a valid value')
    .max(512)
    .label('Enquiry message'),
});
