import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import 'rc-tabs/assets/index.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from 'app';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={7000}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName="__toastify"
        theme="light"
      />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
