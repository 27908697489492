import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import { MdOutlineArrowUpward } from 'react-icons/md';

import Nav from 'components/nav';
import LandingPage from 'components/landing-page';
import Footer from 'components/footer';
import GetStartedPage from 'components/get-started-page';

const App: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({
      behavior: 'smooth',
      top: 0,
    });
  }, [pathname]);

  return (
    <main className="layout">
      <ScrollToTop
        smooth
        color="#fff"
        component={<MdOutlineArrowUpward size={25} color="#fff" />}
        style={{
          borderRadius: '500px',
          background: '#787878',
          height: '60px',
          width: '60px',
          color: '#fff',
        }}
      />
      <header className="layout__header-section">
        <Nav />
      </header>

      <div className="layout__content-section">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/get-started" element={<GetStartedPage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>

      <footer className="layout__footer-section">
        <Footer />
      </footer>
    </main>
  );
};

export default App;
