import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tabs, { TabPane } from 'rc-tabs';

import SendMoneyImg from 'assets/images/send-money.jpg';
import PosCardImg from 'assets/images/pos-card.jpg';
import PaymentIcon from 'assets/images/payments.svg';
import EcommerceIcon from 'assets/images/ecommerce.svg';
import DigitizationIcon from 'assets/images/digitization.svg';
import WomanLaptopImg from 'assets/images/woman-laptop.jpg';
import LeadVoucherLogo from 'assets/images/leadvoucher.svg';
import LeadrMall from 'assets/images/leadrmall.svg';
import LeadVoucherCoupleImg from 'assets/images/leadvoucher-couple.jpg';
import LeadrMallGirl from 'assets/images/leadrmall-girl.jpg';
import AfricanWomanImg from 'assets/images/african-woman.jpg';
import PaylinkImg from 'assets/images/paylink-excited-woman.jpg';
import VirtualTerminalImg from 'assets/images/virtual-terminal-woman.jpg';
import VirtualTerminalLogo from 'assets/images/virtual-terminal.svg';
import QRCodeImg from 'assets/images/qrcode-image.jpg';
import QRCodeLogo from 'assets/images/qrcode-icon.svg';
import IntegrateImg from 'assets/images/integrate-img.jpg';
import BlueVector from 'assets/images/bottom-left-blue.svg';
import OrangeVector from 'assets/images/bottom-right-orange.svg';
import RightArrow from 'assets/images/right-arrow.svg';
import IOSImg from 'assets/images/ios.svg';
import AndroidImg from 'assets/images/android.svg';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <main className="landing-page">
      <section className="landing-page__intro grid-2">
        <div className="landing-page__intro-info">
          <div className="space-grotesk lg-text intro-title mb-1">
            Payments Just Got a Whole Lot Easier
          </div>

          <div className="intro-text mb-1">
            Leadremit creates all-round solutions that empower businesses of any
            size to rise to the top in their segment and take the lead.
          </div>

          <div className="intro__actions">
            <Menu
              menuButton={
                <MenuButton className="btn btn-primary btn-lg space-grotesk">
                  Get Started
                </MenuButton>
              }
            >
              <MenuItem onClick={() => navigate('/get-started')}>
                Contact Sales
              </MenuItem>
            </Menu>

            {/* <Link
              to="/get-started"
              className="btn btn-primary btn-lg space-grotesk"
            >
              Get Started
            </Link> */}
            <div className="intro__downloads">
              <a href="#!" className="download">
                <img src={IOSImg} alt="Get on IOS" />
              </a>
              <a href="#!" className="download">
                <img src={AndroidImg} alt="Get on Android" />
              </a>
            </div>
          </div>
        </div>

        <Link className="landing-page__intro-img" to="#!">
          <img src={SendMoneyImg} alt="Send-funds-with-leadremit" />
        </Link>
      </section>

      {/* What we do section */}
      <section className="landing-page__what-we-do">
        <div className="btn btn-sm btn-primary-light">What We Do</div>

        <div className="what-we-do-grid">
          <img
            src={PosCardImg}
            alt="Leadremit payment"
            className="what-we-do-grid__img"
          />

          <div className="what-we-do-grid__data">
            <div className="data-item">
              <img
                src={PaymentIcon}
                alt="Leadremit pay"
                className="data-item__img"
              />
              <div className="data-item__title space-grotesk">Payments</div>
              <p className="data-item__text">
                Leveraging on technology in easing current payment hassles
              </p>
            </div>

            <div className="data-item">
              <img
                src={EcommerceIcon}
                alt="Leadremit ecommerce"
                className="data-item__img"
              />
              <div className="data-item__title space-grotesk">ECommerce</div>
              <p className="data-item__text">
                To foster the promotion of <br /> E-commerce and a digital
                economy in Africa
              </p>
            </div>

            <div className="data-item">
              <img
                src={DigitizationIcon}
                alt="Leadremit Digitization"
                className="data-item__img"
              />
              <div className="data-item__title space-grotesk">Digitization</div>
              <p className="data-item__text">
                Promoting the growth of SMEs and adaptation in a new digital
                world
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Why leadremit section */}
      <section id="why-leadremit" className="landing-page__why-leadremit">
        <div className="btn btn-sm btn-primary-light">Why Leadremit</div>

        <div className="why-leadremit-grid">
          <div className="why-leadremit-grid-img">
            <img src={WomanLaptopImg} alt="Leadremit pay" />
          </div>
          <div className="why-leadremit-grid-data">
            <p className="xl-text space-grotesk text-primary mb-1">
              Power up your business with The Leadremit Payment Gateway{' '}
            </p>

            <p className="why-leadremit-text">
              Quickly go live with Leadremit and experience the future of
              payments with the easiest integration entirely online onboarding,
              feature-rich checkout, and best-in-class performance.
            </p>

            <div className="why-leadremit-tabs">
              <Tabs>
                <TabPane tab="Offline Channel" key="1">
                  <p className="why-leadremit-header space-grotesk">
                    Offline Channel
                  </p>
                  <p className="why-leadremit-text">
                    From debit cards to mobile wallets, we accept multiple modes
                    of payment from all major platforms quickly and securely to
                    improve your customer experience
                  </p>
                </TabPane>
                <TabPane tab="Online Channel" key="2">
                  <p className="why-leadremit-header space-grotesk">
                    Online Channel
                  </p>
                  <p className="why-leadremit-text">
                    Our unparalleled digital array and online payment gateway
                    for this modern age empowers every business - regardless of
                    the size, sector, or phase of automation.
                  </p>
                </TabPane>
                <TabPane tab="Multi Channel" key="3">
                  <p className="why-leadremit-header space-grotesk">
                    Multi Channel
                  </p>
                  <p className="why-leadremit-text">
                    We ensure that all of your sales, payments and inventory are
                    smoothly synced in one location, whether you are a
                    brick-and-mortar firm that is just starting its e-commerce
                    journey or an established online store that is growing into
                    the offline world.
                  </p>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      {/* LeadVoucher section */}
      <section id="leadvoucher" className="landing-page__leadVoucher">
        <img
          src={LeadVoucherCoupleImg}
          alt=""
          className="landing-page__leadvoucher-banner"
        />

        <img src={BlueVector} alt="BLue vesctor" className="blue-vector" />

        <div className="landing-page__leadvoucher-data">
          <img src={LeadVoucherLogo} alt="LeadVouvcher" />

          <p className="leadVoucher-title space-grotesk">
            Boost your business with Prepaid Gift Cards, Gift Vouchers, and much
            more.
          </p>

          <p className="leadVoucher-text">
            Presenting LeadVoucher&reg;, <br />a first-of-its-kind global SaaS
            prepaid gifting platform.
          </p>

          <Link className="link space-grotesk" to="#!">
            <img src={RightArrow} />
            Voucher Gallery
          </Link>
        </div>
      </section>

      {/* LeaderMall section */}
      <section id="leadrmall" className="landing-page__leadrMall">
        <div className="leadrMall-img">
          <img src={LeadrMallGirl} alt="Leadremit pay" />
        </div>

        <img src={OrangeVector} alt="orange vector" className="orange-vector" />

        <div className="leadrMall-data">
          <img src={LeadrMall} alt="leadrmall" />
          <p className="leadrMall-title space-grotesk text-primary mb-1">
            The Easiest way to build your own online business
          </p>

          <p className="leadrMall-text">
            Get your business online in minutes with our beautiful and
            functional online stores. It’s never been easier for you to become
            an online merchant with our in-built payment system, shipping,
            marketing tools, and more.
          </p>

          <a
            target="_blank"
            href="https://www.leadrmall.com"
            className="btn btn-primary btn-lg space-grotesk"
            style={{ marginTop: '2rem' }}
            rel="noreferrer"
          >
            Start selling online
          </a>
        </div>
      </section>

      {/* Pay4You section */}
      <section className="landing-page__pay4u">
        <img src={AfricanWomanImg} alt="Leadremit" className="pay4u-img" />

        <div className="pay4u-data">
          <div className="btn btn-sm btn-primary-light">Pay4You</div>
          <div className="pay4you-text space-grotesk text-primary">
            Let your customers Shop Now & Pay later with{' '}
            <span className="pay4u-bold">Pay4You</span>
          </div>
          <Link
            to="#!"
            className="btn btn-primary btn-lg space-grotesk"
            style={{ marginTop: '2rem' }}
          >
            Find out more
          </Link>
        </div>
      </section>

      {/* SendMoney section */}
      <section className="landing-page__send-money">
        <div className="btn btn-sm btn-secondary">Send Money</div>
        <p className="send-money-text space-grotesk">
          Money transfer across borders just got easier with <b>Leadremit</b>
        </p>
        <a
          href="https://leadremit.com/"
          target="_blank"
          rel="noreferrer"
          referrerPolicy="no-referrer"
          className="btn btn-secondary btn-xl space-grotesk"
          style={{ marginTop: '2rem' }}
        >
          Send Money Now
        </a>
      </section>

      {/* Paylink section */}
      <section className="landing-page__paylink">
        <img src={PaylinkImg} alt="Leadremit" className="paylink-img" />
        <div className="paylink-data">
          <div className="btn btn-sm btn-primary-light">PayLink</div>
          <div className="paylink-text space-grotesk text-primary">
            <span className="paylink-bold">PayLink</span> allows your customer
            shop and pay via a secured and shared link
          </div>
          <Link
            to="#!"
            className="btn btn-primary btn-lg space-grotesk"
            style={{ marginTop: '2rem' }}
          >
            Get Started
          </Link>
        </div>
      </section>

      {/* VirtualTerminal section */}
      <section className="landing-page__virtual-terminal">
        <img
          src={VirtualTerminalImg}
          alt="Leadremit"
          className="virtual-terminal-img"
        />
        <div className="virtual-terminal-data">
          <img
            src={VirtualTerminalLogo}
            alt="Leadremit"
            className="virtual-terminal-logo"
          />
          <p className="virtual-terminal-title space-grotesk">
            Virtual Terminal
          </p>
          <p className="virtual-terminal-text">
            Simplify your payment collection with Leadremit virtual payment
            terminal, a Web-based version of your POS device that allows
            merchants to process orders made by mail over the phone or on social
            media. Our Virtual terminal facilitates payments for small
            businesses that might otherwise have difficulty accepting card
            transactions.
          </p>

          <Link className="virtual-terminal-link" to="#!">
            <img src={RightArrow} />
            Learn More
          </Link>
        </div>
      </section>

      {/* QRCode section */}
      <section className="landing-page__qrcode">
        <img src={QRCodeImg} alt="Leadremit" className="qrcode-img" />

        <div className="qrcode-data">
          <img src={QRCodeLogo} alt="Leadremit" className="qrcode-logo" />
          <p className="qrcode-title space-grotesk">QR Code</p>
          <p className="qrcode-text">
            Multiply your business with the all new multifeature QR payment
            method; Use Leadremit QR Codes to receive payments from your
            customers. Create QR Codes from the Leadremit Dashboard or using our
            APIs and share them with your customers. Customers can scan these
            codes and make payments.
          </p>

          <Link className="qrcode-link" to="#!">
            <img src={RightArrow} alt="" />
            Learn More
          </Link>
        </div>
      </section>

      {/* Integration Section */}
      <section className="landing-page__integration">
        <img src={IntegrateImg} alt="leadremit" className="integrate-img" />
        <div className="integration-data">
          <p className="integration-title space-grotesk">
            The Easiest Integration Ever
          </p>
          <p className="integration-text">
            With well-documented SDKs, RESTful APIs, and plugins for all major
            platforms and languages, integrating payments with Leadremit is as
            straightforward as you can ever dream.{' '}
          </p>
          <p className="integration-subhead space-grotesk">Easy to integrate</p>
          <p className="integration-text">
            Leadremit Checkout is a cross-platform payment form that requires
            only a few lines of javascript to incorporate. There will be no
            additional integration issues wasting your time.
          </p>
          <p className="integration-subhead space-grotesk">
            Responsive works everywhere
          </p>
          <p className="integration-text">
            Checkout has been thoroughly tested on all main platforms and
            browsers to ensure perfect adaptability.
          </p>
          <Link className="integration-link" to="#!">
            <img src={RightArrow} />
            Integrate with Leadremit
          </Link>
        </div>
      </section>

      {/* JOin now section */}
      <section className="landing-page__join">
        <div className="join-data">
          <p className="join-text space-grotesk">Join Leadremit now</p>
          <p className="join-title">Transfer money from anywhere to Nigeria</p>
        </div>

        <div className="join-btn">
          <a
            href="https://leadremit.com/"
            target="_blank"
            rel="noreferrer"
            referrerPolicy="no-referrer"
            className="btn btn-xl btn-success space-grotesk"
          >
            Send Money Now
          </a>
        </div>
      </section>
    </main>
  );
};

export default LandingPage;
